<template>
  <div>
    <v-simple-table>
      <template #default>
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('Profile Picture') }}</th>
            <th>{{ $t('National Card Picture') }}</th>
            <th>{{ $t('Social Order Picture') }}</th>
            <th>{{ $t('Full Name') }}</th>
            <th>{{ $t('Social Date') }}</th>
            <th>{{ $t('Salary') }}</th>
            <th>{{ $t('Last Payment Date') }}</th>
            <th style="width: 200px" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, index) in socialSecurities"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td class="py-1">
              <a
                :href="`${$_file_path()}${row.profile_picture}?token=${$_auth_token()}`"
                target="_blank"
              >
                <div style="width: 50px; height: 50px">
                  <img
                    :src="`${$_file_path()}${row.profile_picture}?token=${$_auth_token()}`"
                    style="width: 100%; height: 100%; object-fit: cover"
                    class="rounded"
                    alt=""
                  >
                </div>
              </a>
            </td>
            <td class="py-1">
              <a
                :href="`${$_file_path()}${row.id_card_picture}?token=${$_auth_token()}`"
                target="_blank"
              >
                <div style="width: 50px; height: 50px">
                  <img
                    :src="`${$_file_path()}${row.id_card_picture}?token=${$_auth_token()}`"
                    style="width: 100%; height: 100%; object-fit: cover"
                    class="rounded"
                    alt=""
                  >
                </div>
              </a>
            </td>
            <td class="py-1">
              <a
                v-if="row.order_picture"
                :href="`${$_file_path()}${row.order_picture}?token=${$_auth_token()}`"
                target="_blank"
              >
                <div style="width: 50px; height: 50px">
                  <img
                    :src="`${$_file_path()}${row.order_picture}?token=${$_auth_token()}`"
                    style="width: 100%; height: 100%; object-fit: cover"
                    class="rounded"
                    alt=""
                  >
                </div>
              </a>
            </td>
            <td>{{ row.full_name }}</td>
            <td>{{ row.input_date }}</td>
            <td>{{ $_number_format(row.salary) }}</td>
            <td>{{ row.last_payment.input_date }}</td>
            <td class="text-center">
              <v-btn
                class="bg-info-tonal info--text"
                small
                text
                depressed
                @click="viewSocialSecurity(row)"
              >
                <v-icon left>
                  mdi-eye
                </v-icon>
                {{ $t('View') }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <show-social-security
      :id="id"
      :show-dialog="showDialog"
      @reset-table-data="getSocialSecurity()"
      @close="showDialog = false"
    />
  </div>
</template>

<script>
import axios from 'axios'
import ShowSocialSecurity from '../ShowSocialSecurity.vue'

export default {
  components: {
    ShowSocialSecurity,
  },

  data() {
    return {
      socialSecurities: [],
      formData: {},
      company: {},
      id: 0,
      addDialog: false,
      showDialog: false,
    }
  },

  mounted() {
    this.getSocialSecurity()
  },

  methods: {
    getSocialSecurity() {
      axios.get('social-security/client/', { params: { status: 'archive' } }).then(res => {
        this.socialSecurities = res.data
      })
    },

    viewSocialSecurity(row) {
      this.id = row.id
      this.showDialog = true
    }
  },
}
</script>

<style lang="scss" scoped>
</style>